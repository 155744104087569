//export default 'http://localhost:3090';
//http://ec2-18-232-234-226.compute-1.amazonaws.com:3090

const hostIp = window.API_URL;

export const host = () => hostIp;

export const videoDisplayResolution = { height: 520, width: 820 };

export const blurOptions = {
  mask: 'Mask',
  solid: 'Solid',
  smooth: 'Smooth',
  pixelate: 'Pixelate',
  default: 'Pixelate',
};

export const qualityOptions = {
  low: 28,
  medium: 18,
  high: 5,
  default: 18,
};
